
import React, { useState, useEffect, useContext, useCallback } from 'react'
import { navigate, useLocation } from '@reach/router'

const useStateWithMemo = (initialState)=>{
	const [state, setState] = useState(initialState)

	const setter = useCallback((toChangeState)=>{
		if(state !== toChangeState){
			setState(toChangeState)
		}
	},[])

	return [state, setter]
}

const RelocationCtx = React.createContext({
	duringRelocation: false,
	path: '/',
	onClickLink: () => {},
	onChangeLocation: () => {},
})

const DelayedRouter = ({ children }) => {
	const [duringRelocation, onChangeLocation] = useStateWithMemo(false)
	const [path, onClickLink] = useStateWithMemo('/')

	useEffect(
		() => {
			if (duringRelocation === true) {
				const timer = setTimeout(() => {
					clearTimeout(timer)
					onClickLink(path)
					navigate(path)
					onChangeLocation(false)
				}, 1100)
			}
			console.log(duringRelocation, path)
		},
		[duringRelocation]
	)
	return (
		<RelocationCtx.Provider
			value={{ duringRelocation, onClickLink, path, onChangeLocation }}
		>
			{children}
		</RelocationCtx.Provider>
	)
}

const animateClick = (ref)=>{
	const [left, top] = [ref.current.offsetLeft, ref.current.offsetTop]
	const [width,height] = [ref.current.offsetWidth,ref.current.offsetHeight]
	return {
		top,left,width,height
	}
}


const DelayedLink = ({ activeClassName, to, children, onClick, className }) => {
    const { onClickLink, onChangeLocation } = useContext(RelocationCtx)
	const { pathname } = useLocation()

	return (
			<a
				href={to}
				className={[className, pathname === to ? activeClassName:""].join(' ')}
				onClick={(ev) => {
					ev.preventDefault()
					if(pathname !== to){
						onClick && onClick()
						onChangeLocation(true)
						onClickLink(to)
					}
				}}
			>
				{children}
			</a>
	)
}

export { DelayedRouter, DelayedLink, RelocationCtx }
