import { keyframes } from 'styled-components'

const anim = () => {
	const arr = ['-300%', '-200%', '-100%', 0, '100%', '200%', '300%']
	return arr[Math.floor(Math.random() * arr.length)]
}


const fadeIn1 = keyframes`
			0% {
				opacity: 0.1;
				top:${anim()};
				left:${anim()};
			}
			50% {
				opacity: 0.2;
				z-index: 2;
			}
			100% {
				opacity: 0.1;
				top:${anim()};
				left:${anim()};
			}
		`


const fadeIn2 = keyframes`
0% {
    opacity: 0.1;
    top:${anim()};
    left:${anim()};
}
50% {
    opacity: 0.2;
    z-index: 2;
}
100% {
    opacity: 0.1;
    top:0;
    left:0;
}
`


const fadeIn3 = keyframes`
			0% {
				opacity: 0.1;
				top:${anim()};
				left:${anim()};
			}
			50% {
				opacity: 0.2;
				z-index: 2;
			}
			100% {
				opacity: 0.1;
				top:${anim()};
				left:${anim()};
			}
		`


const fadeIn4 = keyframes`
0% {
    opacity: 0.1;
    top:${anim()};
    left:${anim()};
}
50% {
    opacity: 0.2;
    z-index: 2;
}
100% {
    opacity: 0.1;
    top:0;
    left:0;
}
`


const fadeIn5 = keyframes`
			0% {
				opacity: 0.1;
				top:${anim()};
				left:${anim()};
			}
			50% {
				opacity: 0.2;
				z-index: 2;
			}
			100% {
				opacity: 0.1;
				top:${anim()};
				left:${anim()};
			}
		`


const fadeIn6 = keyframes`
0% {
    opacity: 0.1;
    top:${anim()};
    left:${anim()};
}
50% {
    opacity: 0.2;
    z-index: 2;
}
100% {
    opacity: 0.1;
    top:0;
    left:0;
}
`


const fadeIn7 = keyframes`
			0% {
				opacity: 0.1;
				top:${anim()};
				left:${anim()};
			}
			50% {
				opacity: 0.2;
				z-index: 2;
			}
			100% {
				opacity: 0.1;
				top:${anim()};
				left:${anim()};
			}
		`


const fadeIn8 = keyframes`
0% {
    opacity: 0.1;
    top:${anim()};
    left:${anim()};
}
50% {
    opacity: 0.2;
    z-index: 2;
}
100% {
    opacity: 0.1;
    top:0;
    left:0;
}
`


const fadeIn9 = keyframes`
			0% {
				opacity: 0.1;
				top:${anim()};
				left:${anim()};
			}
			50% {
				opacity: 0.2;
				z-index: 2;
			}
			100% {
				opacity: 0.1;
				top:${anim()};
				left:${anim()};
			}
		`


const fadeIn10 = keyframes`
0% {
    opacity: 0.1;
    top:${anim()};
    left:${anim()};
}
50% {
    opacity: 0.2;
    z-index: 2;
}
100% {
    opacity: 0.1;
    top:0;
    left:0;
}
`



export default [fadeIn1, fadeIn2, fadeIn3, fadeIn4, fadeIn5, fadeIn6, fadeIn7, fadeIn8, fadeIn9, fadeIn10]