import React, { useState, useEffect, useContext } from 'react'
import styled, { keyframes, css } from 'styled-components'
import animations from './animations'
import {RelocationCtx} from '@common'
import { grid__wrapper, grid, grid__content, grid__wrapper__off } from './style.module.scss'

const generateCellProps = () => {
	const width = Math.floor(Math.random() * 3 + 1)
	const height = Math.floor(Math.random() * 2 + 1)

	const top = Math.floor(Math.random() * (11 - height - 3) + 1)
	const left = Math.floor(Math.random() * (11 - width - 3) + 1)

	return {
		row: [top, top + height],
		column: [left, top + left + width],
		anim:animations[Math.floor(Math.random() * animations.length)]
	}
}

const initialCells = () => [
	generateCellProps(),
	generateCellProps(),
	generateCellProps(),
	generateCellProps(),
	generateCellProps(),
	generateCellProps(),
	generateCellProps(),
	generateCellProps(),
	generateCellProps(),
	generateCellProps(),
	generateCellProps(),
	generateCellProps(),
	generateCellProps(),
	generateCellProps(),
	generateCellProps(),
	generateCellProps(),
]
let cells = initialCells()

const animationMixin = (anim) => css`
	animation: 3s ${anim} ease-in-out both;
`

const Cell = styled.div`
	position: relative;
	z-index: 1;
	${({anim}) => animationMixin(anim)}
	animation-delay:${({ delay = 0.5 }) => `${delay}s`};
	background-color: ${({ red }) => `${red ? '#fe4e4e' : '#00a3a8'}`};
	${({ row }) => `grid-row-start: ${row[0]}; grid-row-end: ${row[1]}`};
	${({ column }) =>
		`grid-column-start: ${column[0]}; grid-column-end: ${column[1]}`};
`

const Cells = React.memo(({ cells }) => {
	return cells.map((el, key) => {
		return (
			<Cell
				{...el}
				key={key}
				idx={Number(key)}
				delay={Number(key) * 0.5 + 0.5}
				red={Number(key) >= 10}
			/>
		)
	})
}, () => false)

const Background = () => {
	const {duringRelocation} = useContext(RelocationCtx)
	const [cells, setCells] = useState( initialCells())

	return (
		<div className={duringRelocation ? grid__wrapper__off : grid__wrapper}>
			<div
				className={`${grid}`}
			>
				<Cells cells={cells}/>
			</div>
		</div>
	)
}

export default Background
