import React, {useState} from 'react'
import {
	multicheckbox,
	multicheckbox__item,
	multicheckbox__input,
	multicheckbox__label,
} from './forms.module.scss'

const MultiCheckbox = ({ items=[], 
		itemMapper=(el)=>el, 
		onChange=()=>null, 
		applyButtons }) => {
	const [selectedTags, selectTag] = useState([])
	const onSelectTag = ({slug}) => {
		if (!selectedTags.includes(slug)) {
			const tags = [...selectedTags, slug]
			selectTag(tags)
			onChange(tags)
        }
        else{
			const tags = selectedTags.filter(el => el !== slug)
			selectTag(tags)
			onChange(tags)
        }
    }
	return (
		<React.Fragment>
		<div className={multicheckbox}>
			{items.map((el, key) => {
                const item = itemMapper(el)
				return (
					<div key={key} className={[`${multicheckbox__item}`, item.className || ''].join(' ')}>
                        <input
                            className={`${multicheckbox__input}`}
							type='radio'
							checked={selectedTags.includes(el.slug)}
							onClick={()=> {onSelectTag(item)}}
                            {...item}
						/>
                        <label className={multicheckbox__label}
                            onClick={()=> {onSelectTag(item)}}
                            >{item.name}</label>
					</div>
				)
			})}
		</div>
		{applyButtons && applyButtons({selectedTags})}
		</React.Fragment>
	)
}


export default MultiCheckbox