import React from "react"
import PropTypes from 'prop-types';
import {getSortedSrcSet, getCssSizes} from './logic'




const getClosestValue = (array, value, matcher)=>{
  const width = Number(value.replace('w',''))
  const diffs = []
  for(const [_,__, sizes] of array){
    if(sizes){
      const diff = Math.abs(sizes[0] - width)
      diffs.push(diff)
    }
  }
  const idx = diffs.indexOf(Math.min(...diffs))
  return array[idx]
}


class ResizedImage extends React.Component {
  img = React.createRef()

  state = {
    sortedSrcSet: [],
    sourceUrl: "",
  }

  static defaultProps = {
    size: '150w',
    altText: 'Image on page'
  }

  static getDerivedStateFromProps(props, current_state) {
    if (current_state.sourceUrl !== props.sourceUrl) {
      const { srcSet, size } = props
      const sortedUrlSources = getSortedSrcSet(srcSet)

      let selectedImage = getClosestValue(sortedUrlSources, size)

      if(selectedImage){
        return {
            sourceUrl: selectedImage[0],
            sortedSrcSet: sortedUrlSources,
          }
      }
      else{
        selectedImage = sortedUrlSources[0]
        return {
          sourceUrl: selectedImage[0],
          sortedSrcSet: sortedUrlSources,
        }
      }
    }
    return null
  }

  render() {
    const {
      altText,
      style = {}
    } = this.props
    const { sourceUrl } = this.state
    return <img loading="lazy" alt={altText} src={sourceUrl} style={style}
    />
  }
}

ResizedImage.propTypes = {
  altText: PropTypes.string,
  size: PropTypes.string,
  srcSet: PropTypes.string.isRequired,
  sourceUrl: PropTypes.string.isRequired
}


export default ResizedImage
