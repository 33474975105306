import React, { useRef, useEffect, useMemo, useContext, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import memoize from 'fast-memoize'
import { WindowCtx } from '../../../reduxWrapper'
import { getSortedSrcSet } from '@common/Image'
import {
	parallax,
	parallax__img,
	parallax__inner,
} from './parallax.module.scss'

const img =
	'https://test.api.localhost-group.com/wp-content/uploads/2020/03/web_development_code_programming-1457713.png'

const onScroll = ({ height }, { y }, ref) => {
	if (ref.current) {
		const { offsetTop, offsetHeight, clientHeight } = ref.current
		const scrollPositions = [y, y + height]
		const elementPositions = [
			offsetTop - offsetHeight / 2,
			offsetTop + offsetHeight + offsetHeight / 2,
		]
		const notToParallax = [
			scrollPositions[1] <= elementPositions[0], // jeszcze nie widzę
			scrollPositions[0] >= elementPositions[1], // już nie widzę
		]
		if (!notToParallax.includes(true)) {
			const centerOfImage = offsetTop + offsetHeight / 2
			const newDiff =
				(centerOfImage - (y + height / 2)) / clientHeight * 8
			return -40 + newDiff + '%'
		} else {
			return '-40%'
		}
	} else {
		return '-40%'
	}
}

const getProperImgSize = memoize((image, { width }, size) => {
	let fittedImg
	const sortedSrcSet = getSortedSrcSet(image.srcSet)
	for (const idx in sortedSrcSet) {
		const [properImgSize, _, sizes] = sortedSrcSet[idx]
		// console.log('idx', sizes)
		if(size){
			if (sizes && sizes[0] === size) {
				fittedImg = properImgSize
				break
			}
		}
		else{
			if (sizes && sizes[0] >= width) {
				fittedImg = properImgSize
				break
			}
		}
		
	}
	return fittedImg || img
})

const ParallaxInner = ({ children, value, image, height, size }) => {
	const ref = useRef(null)
	const heightObj = height
		? {
				height: height,
				minHeight: height,
			}
		: {}

	return (
		<div
			ref={ref}
			className={parallax}
			style={{
				...heightObj,
			}}
		>
			<img
				loading="lazy"
				src={getProperImgSize(image, value.window, size)}
				style={{
					transform: `translate(-50%,${onScroll(
						value.window,
						value.scroll,
						ref
					)})`,
					transformStyle: 'preserve-3d',
					backfaceVisibility: 'hidden',
				}}
				className={parallax__img}
			/>
			<div className={parallax__inner}>{children}</div>
		</div>
	)
}

const Parallax = ({ image, children, height, size }) => {
	const { WP } = useStaticQuery(graphql`
		query {
			WP {
				mediaItemBy(slug: "jefferson-santos-9SoCnyQmkzI-unsplash") {
					sourceUrl
					srcSet
					altText
				}
			}
		}
	`)
	return (
		<WindowCtx.Consumer>
			{(value) => (
				<ParallaxInner
					image={image || WP.mediaItemBy}
					size={size}
					value={value}
					height={height}
				>
					{children}
				</ParallaxInner>
			)}
		</WindowCtx.Consumer>
	)
}

export default Parallax
