import React, { useState, useLayoutEffect, useRef } from 'react'
import { Motion, spring, presets } from 'react-motion'

let observerFactory = (onChange, lockFn) =>
	new IntersectionObserver((entries) => {
		const [entry] = entries

		if (entry.isIntersecting) {
			onChange(true)
			lockFn()
		}
	},{ 
        threshold: 0.25
    })

const observeWithCallback = (observable, onChange, lockFn, locked) => {
	const observer = observerFactory(onChange, lockFn)
	if (!locked) {
		observer.observe(observable.current)
	} else {
		observer.unobserve(observable.current)
	}
}

const defaultFrom = (spring)=>({
	opacity: 0, left: -150,
})

const defaultTo = (spring)=>({
	opacity: spring(1),
	left: spring(0, {stiffness: 40, damping: 5})
})

const animatedHOC = (Component, params) => (props) => {
	const [inView, changeForActive] = useState(false)
	const [lockAfterInView, changeLock] = useState(false)
	const observable = useRef()

	useLayoutEffect(
		() => {
			observeWithCallback(
				observable,
				changeForActive,
				() => changeLock(true),
				lockAfterInView
			)
		},
		[observable]
	)

	if (inView) {
		return (
			<Motion
				defaultStyle={defaultFrom(spring)}
				style={defaultTo(spring)}
			>
				{(styles) => {
					return (
						<div
                            style={{
									position: 'relative',
									overflow: 'hidden',
									transformStyle: 'preserve-3d',
									...styles
                                }}
							ref={observable}
						>
							<Component {...props} />
						</div>
					)
				}}
			</Motion>
		)
	}

	return (
        <div
            style={{opacity:0, position:'relative'}}
			ref={observable}
		>
			<Component {...props} />
		</div>
	)
}

const AnimatedSection = animatedHOC(({ children }) => {
	return children
})

export default AnimatedSection
