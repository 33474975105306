import React, { useState, useContext } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { ResizedImage as Image, DelayedLink, RelocationCtx } from '@common'
import {
	header,
	header__logo,
	header__menu,
	header__menu__link,
	header__menu__link__alt,
	header__menu__toggler,
	header__menu__toggler__open,
	header__menu__toggler__close,
	header__nav,
	header__nav__active,
	header__menu__link__active,
	header__nav__item,
	header__nav__item__alt,
	header__nav__item__active,
} from './style.module.scss'

const Logo = () => {
	const { WP } = useStaticQuery(graphql`
		query {
			WP {
				mediaItemBy(slug: "lh-academy-white70") {
					sourceUrl
					srcSet
					altText
				}
			}
		}
	`)
	return <Image {...WP.mediaItemBy} size={'300w'} />
}

const Header = ({ children, location }) => {
	const { duringRelocation } = useContext(RelocationCtx)
	const [isMenuOpen, toggle] = useState(false)
	const visible = duringRelocation
		? ''
		: isMenuOpen === true ? header__nav__active : ''
	const visibleToggler = duringRelocation
		? header__menu__toggler__close
		: isMenuOpen === true
			? header__menu__toggler__open
			: header__menu__toggler__close
	return (
		<header className={header}>
			<div className={header__logo}>
                <DelayedLink to={'/'}
                onClick={() => {
                    toggle(false)
                }}
                >
					<Logo />
				</DelayedLink>
			</div>
			<div className={header__menu}>
				<DelayedLink
					onClick={() => {
						toggle(false)
					}}
					activeClassName={header__menu__link__active}
					to={'/help/'}
					className={`${header__menu__link} ${header__menu__link__alt}`}
				>
					Jak możesz pomóc?
				</DelayedLink>
				<a
					onClick={() => {
						toggle(!isMenuOpen)
					}}
					className={`${header__menu__toggler} ${visibleToggler}`}
				>
					<div />
					<div />
					<div />
				</a>
			</div>
			<nav className={[header__nav, visible].join(' ')}>
				<DelayedLink
					className={header__nav__item}
					activeClassName={header__nav__item__active}
					to={'/about/'}
				>
					O autorze inicjatywy
				</DelayedLink>
				<DelayedLink
					className={header__nav__item}
					activeClassName={header__nav__item__active}
					to={'/why-worth/'}
				>
					Dlaczego to robię?
				</DelayedLink>
				<DelayedLink
				className={[header__nav__item, header__nav__item__alt].join(
					' '
				)}
					activeClassName={header__nav__item__active}
					to={'/live-code-review/'}
				>
					Zapisy na Live Code Review
				</DelayedLink>
				<DelayedLink
				className={header__nav__item}
				activeClassName={header__nav__item__active}
				to={'/includes/'}
			>
				Co możesz poćwiczyć?
			</DelayedLink>
				<a
					className={[header__nav__item, header__nav__item__alt].join(
						' '
					)}
					target='_blank'
					href='https://github.com/Przemocny/zbior-zadan-html-css-js-react'
				>
					Darmowy zbiór zadań
				</a>
				<DelayedLink
					className={header__nav__item}
					activeClassName={header__nav__item__active}
					to={'/help/'}
				>
					Pomóż mojej inicjatywie
				</DelayedLink>
				<DelayedLink
					className={header__nav__item}
					activeClassName={header__nav__item__active}
					to={'/teaching/'}
				>
					Akademia Localhost Group
				</DelayedLink>
			</nav>
		</header>
	)
}

export default Header
