import React, { useContext, useEffect } from 'react'
import { Head, Background, Header } from '@common'
import { DelayedRouter, DelayedLink, RelocationCtx } from '@common/DelayedLink'

import { main__wrapper, main__wrapper__off } from './style.module.scss'

const Main = (props) => {
	const { duringRelocation } = useContext(RelocationCtx)
	return (
		<main
			className={[
				duringRelocation ? main__wrapper__off : main__wrapper,
			].join(' ')}
		>
			{props.children}
		</main>
	)
}

const Layout = ({ children, location, page }) => {
	useEffect(() => {
		window.scrollTo(0, 1)
		window.scrollTo(0, 0)
	}, [])

	return (
		<React.Fragment>
			<Head location={location} seo={page.seo} />
			<DelayedRouter>
				<Background />
				<Header />
				<Main>{children}</Main>
			</DelayedRouter>
		</React.Fragment>
	)
}

export default Layout
