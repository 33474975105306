/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

const siteBasic = {
  title:'',
  description:'',
  lang:'',
}

const seoBasic = {
  title: "Page",
  opengraphTitle: "",
  metaKeywords: "",
  metaDesc: "",
  metaRobotsNofollow: "",
  metaRobotsNoindex: "",
  opengraphDescription: "",
  opengraphImage: null
}
 

function Head({ siteMetaData={}, seo={}, ogType="website", location }) {
  const {allFile} = useStaticQuery(graphql`
  query MyQuery {
    allFile {
      nodes {
        name
        publicURL
      }
    }
  }
  
  `)
  const [openGraph, favicon] = allFile.nodes
 
  return (
    <Helmet
      htmlAttributes={{
        lang:siteMetaData.language || siteBasic.lang
      }}
      title={`${seo.title}`}
      meta={[
        {
          name: `description`,
          content: seo.opengraphDescription,
        },
        {
          name: `keywords`,
          content: seo.focuskw || '',
        },
        {
          property: `og:title`,
          content: seo.title || siteMetaData.title || siteBasic.description,
        },
        {
          property: `og:description`,
          content: seo.opengraphDescription || siteMetaData.description || siteBasic.description,
        },
        {
          property: `og:image`,
          content: "https://zbior-zadan-html-css-javascript-react.localhost-group.com" + openGraph.publicURL
        },
        {
          property: `og:image:alt`,
          content: seo.title,
        },
        {
          property: `og:type`,
          content: ogType,
        },
        {
          property: `og:url`,
          content: "https://zbior-zadan-html-css-javascript-react.localhost-group.com" + location.pathname,
        },
      ]}
    >
      <link rel="icon" href={favicon.publicURL}></link>
      <link rel="preconnect dns-prefetch" href="https://cdnjs.cloudflare.com"/>
      <link rel="preconnect dns-prefetch" href="https://fonts.googleapis.com"/>
      
      <link async rel="preload" href="https://fonts.googleapis.com/css?family=Poppins:300,400,600,700&display=swap" rel="stylesheet"/> 
      <link async rel="preload" href="https://fonts.googleapis.com/css?family=Roboto:300,400,700,800&display=swap" rel="stylesheet"/> 

      <script async defer type="text/javascript">{`
        window.replainSettings = { id: 'c230a18c-f3d7-491c-b74f-d00f4e662bea' };
        (function(u){var s=document.createElement('script');s.type='text/javascript';s.async=true;s.src=u;
        var x=document.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);
        })('https://widget.replain.cc/dist/client.js');
    `}</script>
    </Helmet>
  )
}

export default Head
